import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import URL from 'url'

import * as Configuration from './configuration'
import * as ClientConfiguration from './client-configuration'
import { toImageUrl } from './dynamic-image'

/**
 * The hook to return a processed Imgproxy URL
 * @param {?string} url Original image URL
 * @param {?Array|?Object} transforms Desired parameters to process the image.
 * @return {Array} Processed Imgproxy URLs.
 * If an object is passed in transforms, will return an array with 1 resulting
 * URL, if an array is passed in transforms, will return an array of URLs.
 */
export function useImgproxyUrl(url, transforms) {
  const imgproxyUrl = ReactRedux.useSelector(state =>
    Configuration.getImgproxyUrl(state.configuration)
  )
  const dpr = ClientConfiguration.useDevicePixelRatio()
  const hasWebpSupport = ReactRedux.useSelector(state =>
    Configuration.getHasWebpSupport(state.configuration)
  )

  if (!url) {
    return []
  }

  return Array.isArray(transforms)
    ? transforms.map(transform =>
        toImageUrl(imgproxyUrl, url, {
          ...transform,
          dpr,
          extension: hasWebpSupport ? 'webp' : R.path(['extension'], transform),
        })
      )
    : [
        toImageUrl(imgproxyUrl, url, {
          ...transforms,
          dpr,
          extension: hasWebpSupport
            ? 'webp'
            : R.path(['extension'], transforms),
        }),
      ]
}

/**
 * The hook to return a processed Imgproxy URL for a translation image
 * @param {?string} url Original image URL (translation image)
 * @param {?Array|?Object} transforms Desired parameters to process the image.
 * @return {Array} Processed Imgproxy URLs.
 * If an object is passed in transforms, will return an array with 1 resulting
 * URL, if an array is passed in transforms, will return an array of URLs.
 */
export function useTranslationImgproxyUrl(url, transforms) {
  const { pathname } = URL.parse(url)

  return useImgproxyUrl(pathname, transforms)
}
